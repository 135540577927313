import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { BrandProvider } from '@contexts/BrandContext';
import { OperationsProvider } from '@contexts/OperationsContext';
import { PagesNavigation } from '@pages/pages.navigation';
import './App.css';

const queryClient = new QueryClient();

export const App: FC = () => {
  return (
    <IntlProvider locale="en" defaultLocale="en" messages={{}}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <OperationsProvider>
            <BrandProvider>
              <PagesNavigation />
            </BrandProvider>
          </OperationsProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </IntlProvider>
  );
};
