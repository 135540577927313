import { Oval } from 'react-loader-spinner';
import styled, { CSSProperties } from 'styled-components';

import { colors } from '@theme/colors.theme';
import { Breakpoint, createBreakpoints } from '@utils/general.utils';

export interface ButtonColorProperty {
  backToggled?: string;
  backIdle?: string;
  textToggled?: string;
  textIdle?: string;
  borderToggled?: string;
  borderIdle?: string;
}

const Container = styled.button<{
  active?: boolean;
  isToggle?: boolean;
  breakpoints?: Breakpoint[];
  colorProperties?: ButtonColorProperty;
  hasRightNumber?: boolean;
  isDisabled?: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.hasRightNumber ? 'space-between' : 'center'};
  border-radius: 4px;
  background-color: ${(props) => {
    if (props.isToggle && props.active)
      return props.colorProperties?.backToggled || colors.primary;
    else if (props.isToggle)
      return props.colorProperties?.backIdle || colors.white;
    else return props.colorProperties?.backIdle || colors.primary;
  }};

  opacity: ${(props) => {
    return props.disabled ? '0.5' : '1';
  }};

  border: solid 1px
    ${(props) => {
      if (props.isToggle && props.active)
        return props.colorProperties?.borderToggled || colors.primary;
      else return props.colorProperties?.borderIdle || colors.primary;
    }};

  height: 44px;
  padding: 0 ${(props) => (props.hasRightNumber ? '16px' : '29px')};

  :focus {
    outline: none;
  }

  ${(props) => createBreakpoints(props.breakpoints)};
`;

const ButtonText = styled.span<{
  active?: boolean;
  isToggle?: boolean;
  colorProperties?: ButtonColorProperty;
}>`
  color: ${(props) => {
    if (props.isToggle && props.active)
      return props.colorProperties?.textToggled || colors.white;
    else if (props.isToggle)
      return props.colorProperties?.textIdle || colors.primary;
    else return props.colorProperties?.textIdle || colors.white;
  }};
`;

const ButtonRightNumber = styled.span<{
  active?: boolean;
  isToggle?: boolean;
  colorProperties?: ButtonColorProperty;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => {
    if (props.isToggle && props.active)
      return props.colorProperties?.backToggled || colors.white;
    else if (props.isToggle)
      return props.colorProperties?.backIdle || colors.primary;
    else return props.colorProperties?.backIdle || colors.white;
  }};

  color: ${(props) => {
    if (props.isToggle && props.active)
      return props.colorProperties?.textToggled || colors.white;
    else if (props.isToggle)
      return props.colorProperties?.textIdle || colors.primary;
    else return props.colorProperties?.textIdle || colors.white;
  }};

  border: solid 1px
    ${(props) => {
      if (props.isToggle && props.active)
        return props.colorProperties?.borderToggled || colors.primary;
      else return props.colorProperties?.borderIdle || colors.primary;
    }};

  border-radius: 3px;
  padding: 2px 4px;
`;

type ButtonProps = {
  text: string;
  style?: CSSProperties;
  active?: boolean;
  onClick?: () => void;
  loading?: boolean;
  breakpoints?: Breakpoint[];
  disabled?: boolean;
  isToggle?: boolean;
  rightNumber?: number;
  rightNumberColorProperties?: ButtonColorProperty;
  colorProperties?: ButtonColorProperty;
};

export const Button = (props: ButtonProps) => {
  const hasRightNumber = !(props.rightNumber !== 0 && !props.rightNumber);

  return (
    <Container
      style={props.style}
      onClick={props.onClick}
      active={props.active}
      breakpoints={props.breakpoints}
      isToggle={props.isToggle}
      hasRightNumber={hasRightNumber}
      colorProperties={props.colorProperties}
      disabled={props.loading || props.disabled}
      isDisabled={props.disabled}
    >
      {props.loading ? (
        <Oval
          color={colors.white}
          secondaryColor={colors.paleBlueSecondary}
          height={16}
          width={16}
        />
      ) : !hasRightNumber ? (
        <ButtonText
          isToggle={props.isToggle}
          colorProperties={props.colorProperties}
          active={props.active}
        >
          {props.text}
        </ButtonText>
      ) : (
        <>
          <ButtonText
            isToggle={props.isToggle}
            colorProperties={props.colorProperties}
            active={props.active}
          >
            {props.text}
          </ButtonText>
          <ButtonRightNumber
            isToggle={props.isToggle}
            colorProperties={props.rightNumberColorProperties}
            active={props.active}
          >
            {props.rightNumber}
          </ButtonRightNumber>
        </>
      )}
    </Container>
  );
};
