import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

export const KPIGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px 10px;

  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px 5px;
  }

  @media only screen and (max-width: 375px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 5px 5px;
  }
`;

export const KPIContainer = styled('div')<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isLoading ? 'center' : 'start')};
  background-color: ${colors.background};
  border-radius: 2px;
  border: solid 1px ${colors.border};
  justify-content: center;
  padding: 24px;
`;

export const KPITitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const KPITitle = styled.span`
  color: ${colors.primary};
  font-family: Helvetica;
  font-size: 12px;
  letter-spacing: 1.03px;
  margin-bottom: 10px;
`;

export const KPIValueText = styled.span`
  color: ${colors.primary};
  font-family: 'Domaine Disp';
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.92px;
`;

export const TopHomeDashboardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

export const HomeDashboardBrandImageContainer = styled('div')<{
  operation?: { horizontalImage?: string; verticalImage?: string };
}>`
  width: 288px;
  margin-left: 24px;
  height: auto;
  background-size: cover;
  background-position: center center;
  background-image: ${(props) =>
    props.operation?.verticalImage
      ? `url("${props.operation?.verticalImage}")`
      : null};

  @media only screen and (max-width: 1300px) {
    width: 150px;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 175px;
    margin-left: 0;
    margin-bottom: 16px;
    margin-top: -10px;
    background-image: ${(props) =>
      props.operation?.horizontalImage
        ? `url("${props.operation.horizontalImage}")`
        : null};
  }

  @media only screen and (max-width: 700px) {
    margin-top: -4px;
  }
`;

export const HomeDashboardBrandImage = styled('div')<{
  operation?: { horizontalImage?: string; verticalImage?: string };
}>`
  width: 100%;
  height: auto;

  @media only screen and (max-width: 1024px) {
  }
`;

export const HomeDashboardKPIContainer = styled.div`
  width: calc(100% - 312px);

  @media only screen and (max-width: 1300px) {
    width: calc(100% - 174px);
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;
