import axios from 'axios';
import { createRoot } from 'react-dom/client';

import './index.css';
import { App } from './App';
import { environment } from './environments';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('app');
const root = createRoot(container);

axios.defaults.baseURL = environment.apiUrl;

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
