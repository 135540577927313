export const colors = {
  primary: '#002961',
  background: '#FCF5EE',
  border: '#f1eeec',
  accent: '#FF6943',
  accentRGB: '255, 105, 67',
  darkGreen: '#004C48',
  paleGreen: '#DAE8D5',
  paleBlue: '#5588CB',
  paleBlueSecondary: '#B0BDDA',
  light: '#F8F9FA',
  transparent: 'transparent',
  placeholder: '#938e8b',
  pieChart45_54: '#003B8B',
  pieChart35_44: '#0058D0',
  pieChart24_34: '#4091FF',
  pieChart18_24: '#7AB3FF',
  white: '#FFF',
};
