import { FC } from 'react';
import styled from 'styled-components';

import { Title, TitleBullet } from './general.style';

const DashboardTitleContainer = styled('div')<{
  marginBottom?: number;
  marginTop?: number;
}>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom + 'px' : '16px'};
  margin-top: ${(props) => (props.marginTop ? props.marginTop + 'px' : '0')};
`;

interface Props {
  title: string;
  customMarginBottom?: number;
  customMarginTop?: number;
  customFontSize?: number;
}

export const DashboardTitle: FC<Props> = ({
  title,
  customMarginBottom,
  customMarginTop,
  customFontSize,
}) => {
  return (
    <DashboardTitleContainer
      marginBottom={customMarginBottom}
      marginTop={customMarginTop}
    >
      <TitleBullet />
      <Title customFontSize={customFontSize}>{title.toUpperCase()}</Title>
    </DashboardTitleContainer>
  );
};
