import { Link, useLocation } from 'react-router-dom';

import { OperationsSelect } from '../operations/OperationsSelect.component';

import { useBrandContext } from '@contexts/BrandContext';
import { useOperationsContext } from '@contexts/OperationsContext';
import {
  BrandLogo,
  HorizontalSeparator,
  LogoContainer,
  NavigationContainer,
  NavigationTitle,
  SidebarContainer,
  SidebarSubContainer,
} from '@style-components/sidebar.style';
import { SidebarNavigationItem } from '@style-components/SidebarNavigationItem.component';
import { extractBrandUUIDFromPath } from '@utils/general.utils';
import { generateOperationsSelectData } from '@utils/operation.utils';

interface Props {
  isOpen: boolean;
  fullHeight: number;
}

export const Sidebar = ({ isOpen, fullHeight }: Props) => {
  const { setCurrentOperation, operations, currentOperation } =
    useOperationsContext();
  const { brand } = useBrandContext();
  const location = useLocation();

  const onOperationSelectChange = (newValue) => {
    setCurrentOperation(newValue.value);
  };

  const operationsSelectData = generateOperationsSelectData(operations);

  const currSelectedOperation = operationsSelectData.find(
    (operation) => operation.value === currentOperation?.id,
  );

  const brandUUID = extractBrandUUIDFromPath(location.pathname);

  return (
    <SidebarContainer isOpen={isOpen} fullHeight={fullHeight}>
      <SidebarSubContainer>
        <LogoContainer>
          <Link to={`/${brandUUID}`}>
            {brand.logo && <BrandLogo src={brand.logo} />}
          </Link>
        </LogoContainer>
        <NavigationContainer>
          <NavigationTitle addLeftMargin>SALES</NavigationTitle>
          <SidebarNavigationItem label="Overview" itemPathName="" />
          <SidebarNavigationItem label="Products" itemPathName="products" />
          <SidebarNavigationItem
            label="Demographics"
            itemPathName="demography"
          />
        </NavigationContainer>
      </SidebarSubContainer>

      <SidebarSubContainer>
        <HorizontalSeparator />
        <NavigationTitle noBottomMargin>OPERATION</NavigationTitle>
        <OperationsSelect
          operationValues={operationsSelectData}
          value={currSelectedOperation}
          onChangeValue={onOperationSelectChange}
        />
      </SidebarSubContainer>
    </SidebarContainer>
  );
};
