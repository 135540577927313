import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

const RetrocessionValueContainer = styled('div')<{ isLoading?: boolean }>`
  display: flex;
  color: ${colors.accent};
  font-size: 62px;
  margin-bottom: 12px;
  letter-spacing: 1.78px;
  align-items: center;
  justify-content: start;
  font-weight: bold;

  @media only screen and (max-width: 700px) {
    justify-content: ${(props) => (props.isLoading ? 'center' : 'start')};
  }

  @media only screen and (max-width: 375px) {
    font-size: 50px;
  }
`;

interface Props {
  value: number;
  isLoading?: boolean;
}

export const RetrocessionValue: FC<Props> = ({ value, isLoading }) => {
  const intl = useIntl();

  const retrocessionValue = intl.formatNumber(value, {
    style: 'currency',
    currency: 'EUR',
  });

  return (
    <RetrocessionValueContainer>
      {isLoading ? (
        <Oval
          color={colors.paleBlue}
          secondaryColor={colors.paleBlueSecondary}
          height={45}
          width={45}
        />
      ) : (
        retrocessionValue
      )}
    </RetrocessionValueContainer>
  );
};
