import { ProductsSoldPerDay } from '@tb-brand-dashboard/types';

export const calculateSumOfProducts = (
  productsSoldPerDay?: ProductsSoldPerDay,
): number => {
  if (!productsSoldPerDay) {
    return 0;
  }

  return Object.values(productsSoldPerDay).reduce((acc, curr) => acc + curr, 0);
};
