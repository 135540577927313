import styled from 'styled-components';

export const ProductsKPIsContainer = styled.div`
  display: flex;
`;

export const ProductsKPIGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 270px);
  gap: 0 24px;

  @media only screen and (max-width: 830px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 12px;
  }

  @media only screen and (max-width: 500px) {
    gap: 0 8px;
  }

  @media only screen and (max-width: 430px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px 0;
  }
`;

export const ProductsFilterBarContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: space-between;

  @media only screen and (max-width: 830px) {
    flex-direction: column;
    padding-top: 8px;
  }
`;

export const ProductsFilterSubContainer = styled('div')<{
  left?: boolean;
  right?: boolean;
}>`
  display: flex;
  flex-direction: row;

  justify-content: ${(props) => {
    if (props.left) return 'start';
    else if (props.right) return 'end';
    else if (!props.left && !props.right) return 'center';
  }};
  align-items: center;

  ${(props) => {
    if (props.right) {
      return `margin-left: 16px;
			@media only screen and (max-width: 830px) {
				margin-top: 8px;
				margin-left: 0;
			}`;
    }
  }}

  ${(props) => {
    if (props.left) {
      return `@media only screen and (max-width: 650px) {
				flex-direction: column;
			}`;
    }
  }}
`;

export const Arrow = styled('img')<{
  up?: boolean;
}>`
  margin-left: 8px;
  ${(props) => (props.up ? 'transform: rotate(180deg)' : null)};
  height: 6px;
  width: 12px;
`;

export const ProductImage = styled('img')<{
  clickable?: boolean;
  imageSize?: number;
}>`
  height: ${(props) => (props.imageSize ? props.imageSize : 62)}px;
  width: ${(props) => (props.imageSize ? props.imageSize : 62)}px;

  ${(props) =>
    props.clickable
      ? `&:hover {
		cursor: pointer;
	}`
      : ''}
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 62px;
  width: 62px;
`;
