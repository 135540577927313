import styled from 'styled-components';

export const StickyHeader = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: -40px;

  background-color: white;

  @media only screen and (max-width: 1024px) {
    top: -32px;
  }
  @media only screen and (max-width: 830px) {
    top: -20px;
  }

  @media only screen and (max-width: 700px) {
    top: -14px;
  }
`;
