import { VisitorsPerDay, ProductsSoldPerDay } from '@tb-brand-dashboard/types';
import { parse, format } from 'date-fns';

type PrepareChartDataParams = {
  visitorsPerDay?: VisitorsPerDay;
  productsSoldPerDay?: ProductsSoldPerDay;
};

export const prepareChartData = ({
  visitorsPerDay,
  productsSoldPerDay,
}: PrepareChartDataParams) => {
  const chartData = [];

  if (!visitorsPerDay || !productsSoldPerDay) {
    return chartData;
  }

  const dates = [
    ...new Set(
      [
        ...Object.keys(visitorsPerDay),
        ...Object.keys(productsSoldPerDay),
      ].filter((date) => date !== 'undefined'),
    ),
  ].sort();

  for (const date of dates) {
    const parsedDate = parse(date, 'yyyyMMdd', new Date());

    chartData.push({
      name: format(parsedDate, 'dd MMM'),
      Visitors: visitorsPerDay[date] || 0,
      'Products sold': productsSoldPerDay[date] || 0,
    });
  }

  return chartData;
};
