import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

const Container = styled.div`
  display: flex;
  flex: 1;
  position: fixed;

  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 2;
`;

const ImageViewerOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 62px;
  width: 62px;
`;

const Image = styled.img`
  max-height: 75vh;
  max-width: 25vw;

  @media only screen and (max-width: 1400px) {
    max-height: 80vh;
    max-width: 30vw;
  }

  @media only screen and (max-width: 1100px) {
    max-width: 35vw;
  }

  @media only screen and (max-width: 1024px) {
    max-height: 80vh;
    max-width: 45vw;
  }

  @media only screen and (max-width: 700px) {
    max-height: 80vh;
    max-width: 90vw;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 8px;
  top: 8px;
  height: 24px;
  width: 24px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border: solid 1px ${colors.primary};

  &:hover {
    cursor: pointer;
  }
`;

type ProductImageViewerProps = {
  imgSrc: string;
  setImageToShowSrc: (string) => void;
};

export const ProductImageViewer = (props: ProductImageViewerProps) => {
  const [loading, setLoading] = useState(true);
  const resetImage = () => {
    props.setImageToShowSrc('');
  };

  return (
    <Container>
      <ImageViewerOverlay onClick={resetImage} />
      <ImageContainer>
        {loading && (
          <LoaderContainer>
            <Oval
              color={colors.paleBlue}
              secondaryColor={colors.paleBlueSecondary}
              height={50}
              width={50}
            />
          </LoaderContainer>
        )}

        {!loading && (
          <IconContainer onClick={resetImage}>
            <MdClose size={18} color={colors.primary} />
          </IconContainer>
        )}

        <Image src={props.imgSrc} alt="" onLoad={() => setLoading(false)} />
      </ImageContainer>
    </Container>
  );
};
