import { useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { Navbar } from '../layouts/Navbar.component';
import { Sidebar } from '../layouts/Sidebar.component';

import { LoadingScreen } from '@components/pages/LoadingScreen';
import { useBrandContext } from '@contexts/BrandContext';
import { useOperationsContext } from '@contexts/OperationsContext';
import { Container } from '@style-components/container.component';
import { SideBarOverlay } from '@style-components/sidebar.style';

const ChildrenContainer = styled('div')<{
  fullHeight?: number;
}>`
  width: 100%;
  height: calc(${(props) => props.fullHeight}px - 131px);
  padding: 40px 24px;
  overflow: auto;

  @media only screen and (max-width: 1024px) {
    padding: 20px 12px;
    height: calc(${(props) => props.fullHeight}px - 91px);
  }

  @media only screen and (max-width: 700px) {
    padding: 10px 6px;
    height: calc(${(props) => props.fullHeight}px - 71px);
  }
`;

const MainContainer = styled.div`
  max-height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
`;

interface Props {
  pageTitle: string;
  children?;
}

export const HeaderSidebarContainer = ({ pageTitle, children }: Props) => {
  const { isOperationsLoading } = useOperationsContext();
  const { isBrandDataLoading } = useBrandContext();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const fullHeight = use100vh();

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const onOverlayClickHandler = () => {
    if (sidebarIsOpen) {
      setSidebarIsOpen(!sidebarIsOpen);
    }
  };

  if (isBrandDataLoading || isOperationsLoading) {
    return <LoadingScreen />;
  }

  return (
    <MainContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Navbar toggleSidebar={toggleSidebar} />

      <Container>
        {/* Page Body */}

        <Sidebar isOpen={sidebarIsOpen} fullHeight={fullHeight} />
        <SideBarOverlay
          isVisible={sidebarIsOpen}
          onClick={onOverlayClickHandler}
        />

        <ChildrenContainer fullHeight={fullHeight} id="scrollContainer">
          {children}
        </ChildrenContainer>
      </Container>
    </MainContainer>
  );
};
