export const extractBrandUUIDFromPath = (currPathname: string) => {
  return currPathname.split('/').splice(1)[0];
};

// BREAKPOINTS UTILS
export interface Breakpoint {
  breakpoint: string;
  properties: { [key: string]: string };
}
export const createBreakpoints = (breakpoints: Breakpoint[]) => {
  if (breakpoints) {
    let style = '';
    for (const breakpoint of breakpoints) {
      if (breakpoint.breakpoint === 'normal') {
        for (const [key, value] of Object.entries(breakpoint.properties)) {
          style += `${key}: ${value};\n`;
        }
      } else {
        style += `@media only screen and (max-width: ${breakpoint.breakpoint}) {\n`;
        for (const [key, value] of Object.entries(breakpoint.properties)) {
          style += `${key}: ${value};\n`;
        }
        style += `}\n`;
      }
    }
    return style;
  } else {
    return '';
  }
};

export const slugify = (str: string): string => {
  const map = {
    a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    e: 'é|è|ê|ë|É|È|Ê|Ë',
    i: 'í|ì|î|ï|Í|Ì|Î|Ï',
    o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    c: 'ç|Ç',
    n: 'ñ|Ñ',
  };

  for (const pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
  }

  return str;
};

export const toLowerCaseIncludes = (
  str: string,
  searchQuery: string,
): boolean => {
  const sluggedStr = slugify(str);
  const sluggedSearchQuery = slugify(searchQuery);
  return sluggedStr.toLowerCase().includes(sluggedSearchQuery.toLowerCase());
};
