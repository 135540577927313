import { FC } from 'react';
import { IconContext } from 'react-icons';
import { VscMenu } from 'react-icons/vsc';
import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

const MenuIconButton = styled.button`
  position: absolute;
  left: 24px;
  border: 0;
  background-color: transparent;
  padding: 0;

  @media only screen and (min-width: 1024px) {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  onClick;
}

export const MenuIcon: FC<Props> = ({ onClick }) => {
  return (
    <MenuIconButton onClick={onClick}>
      <IconContext.Provider
        value={{
          size: '1.5em',
          color: colors.accent,
          style: { verticalAlign: 'middle' },
        }}
      >
        <VscMenu />
      </IconContext.Provider>
    </MenuIconButton>
  );
};
