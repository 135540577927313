import { ComponentProps } from 'react';
import { FormattedNumber } from 'react-intl';
import { Oval } from 'react-loader-spinner';

import {
  KPIContainer,
  KPITitle,
  KPIValueText,
} from '@style-components/kpis.style';
import { colors } from '@theme/colors.theme';

type Props = ComponentProps<typeof FormattedNumber> & {
  label: string;
  subLabel?: string;
  value: number;
  isLoading: boolean;
};

export const KPI = ({
  label,
  value,
  subLabel,
  isLoading,
  ...formattedNumberProps
}: Props) => {
  return (
    <KPIContainer isLoading={isLoading}>
      {isLoading ? (
        <Oval
          color={colors.paleBlue}
          secondaryColor={colors.paleBlueSecondary}
          height={50}
          width={50}
        />
      ) : (
        <>
          <KPITitle>
            {label.toUpperCase()} {subLabel ? subLabel.toUpperCase() : null}
          </KPITitle>

          <KPIValueText>
            <FormattedNumber value={value} {...formattedNumberProps} />
          </KPIValueText>
        </>
      )}
    </KPIContainer>
  );
};
