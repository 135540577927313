import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

export const DemographyMainContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const DemographySubContainer = styled('div')<{
  isNotFirst?: boolean;
}>`
  flex: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 800px) {
    width: 100%;
    margin-bottom: 24px;
    margin-top: ${(props) => (props.isNotFirst ? '40px' : '0')};
  }
`;

export const DemographyKPIContainer = styled.div`
  width: 33.33%;

  @media (max-width: 1550px) {
    width: 50%;
  }

  @media (max-width: 1100px) {
    width: 75%;
  }

  @media (max-width: 1024px) and (min-width: 850px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 50%;
  }
`;

export const MapContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;

  margin-top: 24px;
`;

export const MapSVGContainer = styled.div`
  display: flex;
  flex: 1;
  width: 45%;
  height: 75%;
  min-height: 550px;

  @media only screen and (max-width: 1175px) {
    zoom: 0.9;
  }

  @media only screen and (max-width: 1045px) {
    zoom: 0.85;
  }

  @media only screen and (max-width: 1024px) and (min-width: 850) {
    zoom: 0.9;
  }

  @media (max-width: 850px) {
    zoom: 0.8;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 450px;
    zoom: 1;
  }

  @media (max-width: 500px) {
    zoom: 0.9;
  }

  @media (max-width: 430px) {
    zoom: 0.8;
  }

  @media (max-width: 390px) {
    zoom: 0.75;
  }

  @media (max-width: 360px) {
    zoom: 0.7;
  }

  @media (max-width: 335px) {
    zoom: 0.65;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  font-family: Helvetica, sans-serif;
`;

export const CustomDemographyPieChartTooltip = styled.div`
  background-color: ${colors.background};
  color: ${colors.primary};
  padding: 10px;
  border: 1px solid ${colors.border};
  border-radius: 2px;
  font-family: Helvetica, sans-serif;
`;

export const LoaderContainer = styled.div`
  margin-top: 50px;
`;
