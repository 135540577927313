import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

export const SidebarContainer = styled('div')<{
  isOpen?: boolean;
  fullHeight?: number;
}>`
  width: 219px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${colors.background};
  overflow: hidden;
  padding: 24px 16px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;

  @media only screen and (max-width: 1024px) {
    position: fixed;
    left: ${(props) => (props.isOpen ? '0px' : '-219px')};
    top: 0;
    z-index: 10;
    height: ${(props) => props.fullHeight}px;

    overflow-y: auto;
  }
`;

export const SideBarOverlay = styled('div')<{ isVisible?: boolean }>`
  position: fixed;
  z-index: 2;
  height: calc(100vh);
  width: calc(100vw);
  top: 0;
  left: 0;
  background-color: black;
  display: ${(props) => (props.isVisible ? 'unset' : 'none')};
  opacity: ${(props) => (props.isVisible ? '0.25' : '0')};
  -webkit-transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
`;

export const SidebarSubContainer = styled.div`
  width: 100%;
`;

export const NavigationContainer = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

export const LogoContainer = styled.div`
  margin-bottom: 24px;
`;

export const BrandLogo = styled.img`
  object-fit: contain;
  width: 100%;
  height: auto;
`;

export const NavigationTitle = styled('p')<{
  addLeftMargin?: boolean;
  noBottomMargin?: boolean;
}>`
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.primary};

  margin-left: ${(props) => (props.addLeftMargin ? '16px' : '0')};
  margin-bottom: ${(props) => (props.noBottomMargin ? '0' : '10px')};
  letter-spacing: 1px;
`;

export const NavigationElement = styled('p')<{ active?: boolean }>`
  margin: 0;
  padding: 10px 16px 10px 0px;
  padding-left: ${(props) => (props.active ? '13px' : '16px')};
  margin-bottom: 4px;
  flex: 1;

  font-family: 'Domaine Disp';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.1px;
  color: ${colors.accent};

  background-color: ${(props) =>
    props.active ? 'rgba(' + colors.accentRGB + ', 0.1)' : colors.transparent};

  &:hover {
    background-color: ${'rgba(' + colors.accentRGB + ', 0.1)'};
  }
`;

export const BottomButtonsContainer = styled.div`
  margin: 0 -16px 0 -16px;
`;

export const HorizontalSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.border};

  margin: 16px 0 19px 0;
`;

export const ActiveItemMarker = styled.div`
  left: 0;
  width: 3px;
  margin-bottom: 4px;
  border-radius: 2px;
  background-color: ${colors.accent};
`;
