export enum ProductsFilter {
  BEST_SELLERS = 'bestSellers',
  WORST_SELLERS = 'worstSellers',
  OUT_OF_STOCK = 'outOfStock',
  DOWN_SELL_THROUGH = 'downSellThrough',
  UP_SELL_THROUGH = 'upSellThrough',
  DOWN_AVAILABLE = 'downAvailable',
  UP_AVAILABLE = 'upAvailable',
  DOWN_PRODUCT_NAMES = 'downProductNames',
  UP_PRODUCT_NAMES = 'upProductNames',
}
