import { ProductSellThrough } from '@tb-brand-dashboard/types';
import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import LazyLoad from 'react-lazyload';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

import { ProductListVariants } from '../ProductListVariants/ProductListVariants';
import { ProductVariantInfo } from '../ProductListVariants/ProductListVariants.types';

import { resizeShopifyImage } from './ProductListItem.utils';

import {
  Arrow,
  LoaderContainer,
  ProductImage,
} from '@style-components/products.style';
import {
  ListItemLineRow,
  ListItemSubContainer,
  ListText,
} from '@style-components/tables.style';
import { colors } from '@theme/colors.theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 0;
  min-width: 900px;

  &:hover {
    background-color: ${colors.background};
  }
`;

const ProductTitleContainer = styled('div')<{
  clickable?: boolean;
}>`
  display: flex;
  align-items: center;

  ${(props) => {
    if (props.clickable)
      return `&:hover {
			cursor: pointer;
		}`;
  }}
`;

type ProductListItemProps = {
  product: ProductSellThrough;
  setImageToShowSrc: (string) => void;
};

export const ProductListItem: FC<ProductListItemProps> = ({
  product,
  setImageToShowSrc,
}) => {
  const [loading, setLoading] = useState(true);
  const [showVariants, setShowVariants] = useState(false);
  const intl = useIntl();

  const hasVariants = product.variants.length > 0;

  const onProductTitleClickHandler = () => {
    if (hasVariants) {
      setShowVariants(!showVariants);
    }
  };

  return (
    <Container>
      <ListItemLineRow>
        <ListItemSubContainer width="52%">
          <LazyLoad
            height={62}
            offset={100}
            scrollContainer={'#scrollContainer'}
            once
            style={{ width: 62, height: 62, marginLeft: 12, marginRight: 24 }}
          >
            {product.img_src && (
              <ProductImage
                src={`${resizeShopifyImage(product.img_src, '62x62')}`}
                onLoad={() => setLoading(false)}
                onClick={() => {
                  setImageToShowSrc(product.img_src);
                }}
                clickable
              />
            )}

            {loading && product.img_src && (
              <LoaderContainer>
                <Oval
                  color={colors.paleBlue}
                  secondaryColor={colors.paleBlueSecondary}
                  height={30}
                  width={30}
                />
              </LoaderContainer>
            )}
          </LazyLoad>

          <ProductTitleContainer
            clickable={hasVariants}
            onClick={onProductTitleClickHandler}
          >
            <ListText>{product.product_title}</ListText>
            {hasVariants && (
              <Arrow src="/assets/imgs/orangeArrow.svg" up={showVariants} />
            )}
          </ProductTitleContainer>
        </ListItemSubContainer>
        <ListItemSubContainer width="16%">
          <ListText>{intl.formatNumber(product.quantity_sold)}</ListText>
        </ListItemSubContainer>

        <ListItemSubContainer width="16%">
          <ListText>{intl.formatNumber(product.available_inventory)}</ListText>
        </ListItemSubContainer>
        <ListItemSubContainer width="16%">
          <ListText>
            {intl.formatNumber(product.sell_through, {
              style: 'percent',
              maximumFractionDigits: 2,
            })}
          </ListText>
        </ListItemSubContainer>
      </ListItemLineRow>

      {hasVariants && showVariants && (
        <ListItemLineRow>
          <ListItemSubContainer width="52%">
            <ProductListVariants
              variants={product.variants}
              variantInfo={ProductVariantInfo.TITLE}
              productTitle
            />
          </ListItemSubContainer>
          <ListItemSubContainer width="16%">
            <ProductListVariants
              variants={product.variants}
              variantInfo={ProductVariantInfo.SOLD}
            />
          </ListItemSubContainer>

          <ListItemSubContainer width="16%">
            <ProductListVariants
              variants={product.variants}
              variantInfo={ProductVariantInfo.AVAILABLE}
            />
          </ListItemSubContainer>
          <ListItemSubContainer width="16%">
            <ProductListVariants
              variants={product.variants}
              variantInfo={ProductVariantInfo.SELL_THROUGH}
            />
          </ListItemSubContainer>
        </ListItemLineRow>
      )}
    </Container>
  );
};
