import { Operation } from '@tb-brand-dashboard/types';
import moment from 'moment';

export const generateOperationsSelectData = (operations: Operation[]) => {
  return operations.map((operation) => {
    return {
      value: operation.id,
      label: moment(operation.startDate).local().format('MMMM YY'),
    };
  });
};
