import { useQuery } from '@tanstack/react-query';
import { GetAnalyticsOverviewDataResponse } from '@tb-brand-dashboard/types';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { HeaderSidebarContainer } from '@components/containers/HeaderSidebarContainer.component';
import { KPI } from '@components/dashboards/KPI.component';
import { VisitsProductsSoldChart } from '@components/dashboards/overview/VisitsProductsSoldChart';
import { DashboardTitle } from '@components/style/dashboardTitle.component';
import {
  HomeDashboardBrandImageContainer,
  HomeDashboardKPIContainer,
  KPIGrid,
  TopHomeDashboardContainer,
} from '@components/style/kpis.style';
import { RetrocessionValue } from '@components/style/retrocessionValue.component';
import { useBrandContext } from '@contexts/BrandContext';
import { useOperationsContext } from '@contexts/OperationsContext';
import { getAnalyticsOverviewData } from '@services/analytics.service';
import { calculateSumOfProducts } from '@utils/calculateSumOfProducts';
import {
  calculateConversionRate,
  calculateProportion18_34,
} from '@utils/kpi.utils';

export const HomeScreen = () => {
  const params = useParams<{ uniqueUrl: string }>();

  const { currentOperation, noOperations } = useOperationsContext();
  const { brandNotFound, brand } = useBrandContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (brandNotFound) {
      navigate('/404');
    } else if (noOperations) {
      navigate(`/${brand?.uniqueUrl}/operations`);
    }
  }, [brandNotFound, brand?.uniqueUrl, noOperations, navigate]);

  const { data, isLoading } = useQuery<GetAnalyticsOverviewDataResponse>({
    queryKey: [
      `analytics-overview-${params?.uniqueUrl}-${brand?.id}-${currentOperation?.id}`,
    ],
    queryFn: () => {
      return getAnalyticsOverviewData(
        params?.uniqueUrl,
        brand?.id,
        currentOperation?.id,
      );
    },
  });

  const proportion18_34 = calculateProportion18_34(data?.customersAgeBrackets);

  const conversionRate = calculateConversionRate(
    data?.totalVisitors,
    data?.orders,
  );

  const totalProductsSold = calculateSumOfProducts(data?.productsSoldPerDay);

  return (
    <HeaderSidebarContainer
      pageTitle={`Dashboard ${brand?.name} | The Bradery`}
    >
      <TopHomeDashboardContainer>
        <HomeDashboardKPIContainer>
          <DashboardTitle
            title="retrocession excluding taxes"
            customMarginBottom={9}
          />
          <RetrocessionValue value={data?.retrocession} isLoading={isLoading} />
          <KPIGrid>
            <KPI
              label="visitors"
              value={data?.totalVisitors}
              isLoading={isLoading}
            />
            <KPI label="orders" value={data?.orders} isLoading={isLoading} />
            <KPI
              label="conversion rate"
              value={conversionRate}
              isLoading={isLoading}
              style={'percent'}
              maximumFractionDigits={2}
            />
            <KPI
              label="buyers"
              subLabel="18/34 y.o."
              value={proportion18_34}
              style={'percent'}
              maximumFractionDigits={2}
              isLoading={isLoading}
            />
            <KPI
              label="global sellthrough"
              value={data?.globalSellThrough}
              style={'percent'}
              maximumFractionDigits={2}
              isLoading={isLoading}
            />
            <KPI
              label="products sold"
              value={totalProductsSold}
              isLoading={isLoading}
            />
            <KPI
              label="average cart"
              value={data?.cartAverage}
              style={'currency'}
              currency="EUR"
              maximumFractionDigits={2}
              isLoading={isLoading}
            />
            <KPI
              label="sales index"
              value={data?.salesIndex}
              isLoading={isLoading}
            />
            <KPI
              label="app orders share"
              value={data?.appOrdersShare}
              style={'percent'}
              maximumFractionDigits={2}
              isLoading={isLoading}
            />
            <KPI
              label="desktop site orders share"
              value={data?.desktopSiteOrdersShare}
              style={'percent'}
              maximumFractionDigits={2}
              isLoading={isLoading}
            />
            <KPI
              label="mobile site orders share"
              value={data?.mobileSiteOrdersShare}
              style={'percent'}
              maximumFractionDigits={2}
              isLoading={isLoading}
            />
          </KPIGrid>
        </HomeDashboardKPIContainer>
        <HomeDashboardBrandImageContainer operation={currentOperation} />
      </TopHomeDashboardContainer>

      <DashboardTitle title="visitors / products sold" customMarginTop={64} />
      <VisitsProductsSoldChart
        productsSoldPerDay={data?.productsSoldPerDay}
        visitorsPerDay={data?.visitorsPerDay}
        isLoading={isLoading}
      />
    </HeaderSidebarContainer>
  );
};
