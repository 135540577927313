const prod = {
  apiUrl: 'https://api.dashboard.thebradery.com/v1/',
};

const staging = {
  apiUrl: 'https://staging.dashboard-api.thebradery.com/api/',
};

const dev = {
  apiUrl: 'http://localhost:3000/v1/',
};

export const environment =
  process.env.REACT_APP_ENV === 'PROD'
    ? prod
    : process.env.REACT_APP_ENV === 'STAGING'
    ? staging
    : dev;

// console.log(process.env.REACT_APP_ENV);
// console.log(environment);
