import { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { colors } from '@theme/colors.theme';
import { Breakpoint, createBreakpoints } from '@utils/general.utils';

const Container = styled('div')<{
  breakpoints?: Breakpoint[];
}>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: ${colors.background};
  height: 44px;
  border-radius: 4px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;

  ${(props) => createBreakpoints(props.breakpoints)};
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  margin-left: 10px;
  background-color: transparent;
  font-family: Helvetica, sans-serif;
  letter-spacing: 1.03px;
  color: ${colors.primary};

  :focus {
    outline: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.placeholder};
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.placeholder};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.placeholder};
  }
`;

type IconTextInputProps = {
  onChange?: (text: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  value?: string;
  style?: CSSProperties;
  icon?;
  focusIconColor?: string;
  breakpoints?: Breakpoint[];
};

export const IconTextInput = (props: IconTextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const { icon: Icon } = props;

  const onFocusHandler = () => {
    setIsFocused(true);
  };

  const onBlurHandler = () => {
    setIsFocused(false);
  };

  return (
    <Container style={props.style} breakpoints={props.breakpoints}>
      <Icon
        color={
          isFocused
            ? props.focusIconColor || colors.primary
            : colors.placeholder
        }
      />
      <Input
        type="text"
        placeholder={props.placeholder}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
      />
    </Container>
  );
};
