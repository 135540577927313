import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 51px);
  height: calc(-webkit-fill-available - 51px);
`;

export const MainOverlayContainer = styled('div')<{
  fullHeight?: number;
  isVisible?: boolean;
  customZ?: number;
}>`
  position: fixed;
  z-index: ${(props) => props.customZ + 2 || 2};
  height: ${(props) => props.fullHeight}px;
  width: calc(100vw);
  top: 0;
  left: 0;

  display: ${(props) => (props.isVisible ? 'unset' : 'none')};
`;

export const OverlayContainer = styled('div')<{
  isVisible?: boolean;
}>`
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: ${(props) => (props.isVisible ? '0.25' : '0')};
  -webkit-transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
`;
