import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { NotFoundScreen } from './404/not-found.screen';
import { DemographyScreen } from './demography/demography';
import { HomeScreen } from './overview/overview';
import { ProductsScreen } from './products/products';

export const PagesNavigation: FC = () => {
  return (
    <Routes>
      <Route path="/404" element={<NotFoundScreen />} />
      <Route path="/:uniqueUrl" element={<HomeScreen />} />
      <Route path="/:uniqueUrl/products" element={<ProductsScreen />} />
      <Route path="/:uniqueUrl/demography" element={<DemographyScreen />} />
      <Route path="/:uniqueUrl/:name" element={<NotFoundScreen />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};
