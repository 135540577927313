import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

export const StyledResponsiveContainer = styled.div`
  width: 100%;
  height: 300px;
  background-color: ${colors.background};
  border-radius: 2px;
  border: solid 1px ${colors.border};
  font-family: Helvetica, sans-serif;
`;

export const CustomToolTipContainer = styled.div`
  background-color: white;
`;

export const ChartLoadingContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
