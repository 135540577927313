import { GetBrandResponse } from '@tb-brand-dashboard/types';
import axios from 'axios';

const ROUTE_BASE = 'brands';

// ✅️
export const getBrandData = async (
  uniqueUrl: string,
): Promise<GetBrandResponse> => {
  const res = await axios.get<GetBrandResponse>(`${ROUTE_BASE}`, {
    params: { uniqueUrl },
  });

  return res.data;
};
