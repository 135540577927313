export const resizeShopifyImage = (
  imageURL: string,
  dimensions: string,
  crop: string = 'center',
) => {
  const supportedExtensions = ['.jpg', '.png', '.webp'];

  const imageExtensionIndex = supportedExtensions.reduce(
    (foundIndex, ext) =>
      foundIndex === -1 ? imageURL.indexOf(ext) : foundIndex,
    -1,
  );

  if (imageExtensionIndex === -1) {
    // Handle unsupported extensions or no extension found
    return imageURL;
  }

  const extension = imageURL.substring(imageExtensionIndex);

  return `${imageURL.substring(
    0,
    imageExtensionIndex,
  )}_${dimensions}_crop_${crop}${extension}`;
};
