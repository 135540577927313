import { useQuery } from '@tanstack/react-query';
import { Operation } from '@tb-brand-dashboard/types';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { getBrandOperations } from '@services/operations.service';

type OperationsState = {
  operations?: Operation[];
  currentOperation?: Operation;
  setCurrentOperation: (currentOperationId: number) => void;
  noOperations: boolean;
  isOperationsLoading: boolean;
};

type OperationsProviderProps = {
  children: ReactNode;
};

const OperationsContext = createContext<OperationsState | undefined>(undefined);

export const OperationsProvider: FC<OperationsProviderProps> = ({
  children,
}) => {
  const location = useLocation();

  const uniqueUrl = location.pathname
    .split('/')
    .filter((pathnameElement) => pathnameElement !== '')[0];

  const [currentOperation, setRawCurrentOperation] = useState<Operation>();

  const { data, isLoading: isOperationsLoading } = useQuery({
    queryKey: [`operations-${uniqueUrl}`],
    queryFn: () => {
      return getBrandOperations(uniqueUrl);
    },
  });

  useEffect(() => {
    setCurrentOperation(data?.currOperation);
  }, [data?.currOperation]);

  const operations = data?.operations;
  const noOperations = data?.noOperations;

  const setCurrentOperation = (currentOperationId: number) => {
    const currentOperation = operations?.find(
      (operation) => operation.id === currentOperationId,
    );

    setRawCurrentOperation(currentOperation);
  };

  return (
    <OperationsContext.Provider
      value={{
        currentOperation,
        setCurrentOperation,
        operations,
        noOperations,
        isOperationsLoading,
      }}
    >
      {children}
    </OperationsContext.Provider>
  );
};

export const useOperationsContext = () => {
  const context = useContext(OperationsContext);

  if (!context) {
    throw new Error('Component must be used within a OperationsProvider');
  }

  return context;
};
