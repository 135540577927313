import { FC } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

const ProductCategorySelectionContainer = styled.div`
  width: 171px;
  margin-left: 16px;

  font-family: Helvetica, sans-serif;
  font-size: 13px;

  @media only screen and (max-width: 830px) {
    width: 50%;
    margin-left: 12px;
  }

  @media only screen and (max-width: 650px) {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
`;

const productCategorySelectStyles = {
  menu: (provided) => ({
    ...provided,
    border: `1px solid ${colors.primary}`,
  }),
  control: (base, state) => ({
    ...base,
    background: colors.transparent,
    // Overwrittes the different states of border
    borderColor: colors.primary,
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    paddingLeft: 16,
    paddingRight: 8,
    '&:hover': {
      cursor: 'pointer',
    },
    height: 44,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled
      ? null
      : state.isSelected || state.isFocused
      ? `rgba(${colors.accentRGB}, 0.1)`
      : null,

    color: state.isDisabled
      ? colors.placeholder
      : state.isSelected || state.isFocused
      ? colors.accent
      : colors.primary,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    margin: 0,
    color: colors.accent,
    fontSize: 13,
  }),
};

const DropdownIndicatorImg = styled.img`
  height: 16px;
  width: 16px;
`;

type ProductCategorySelectProps = {
  onChangeValue?: (selection: { value: string; label: string }) => void;
  value?: {
    value: string;
    label: string;
  };
  options?;
};

export const ProductCategorySelect: FC<ProductCategorySelectProps> = ({
  onChangeValue,
  value,
  options,
}) => {
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <DropdownIndicatorImg src="/assets/imgs/chevronDown.svg" alt="" />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <ProductCategorySelectionContainer>
      <Select
        options={options}
        styles={productCategorySelectStyles}
        value={value}
        isSearchable={false}
        components={{ DropdownIndicator }}
        onChange={onChangeValue}
        menuPlacement="auto"
        placeholder="Catégories"
      />
    </ProductCategorySelectionContainer>
  );
};
