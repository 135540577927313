import { useQuery } from '@tanstack/react-query';
import {
  GetAnalyticsProductsDataResponse,
  ProductSellThrough,
} from '@tb-brand-dashboard/types';
import { FC, useEffect, useState } from 'react';
import { forceCheck } from 'react-lazyload';
import { useNavigate, useParams } from 'react-router-dom';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { sortProducts } from './products.utils';

import { HeaderSidebarContainer } from '@components/containers/HeaderSidebarContainer.component';
import { KPI } from '@components/dashboards/KPI.component';
import { ProductImageViewer } from '@components/dashboards/products/ProductImageViewer.component';
import { ProductListHeader } from '@components/dashboards/products/ProductListHeader.component';
import { ProductListItem } from '@components/dashboards/products/ProductListItem/ProductListItem';
import { ProductsFilterBar } from '@components/dashboards/products/ProductsFilterBar/ProductsFilterBar';
import { ProductsFilter } from '@components/dashboards/products/ProductsFilterBar/ProductsFilterBar.types';
import { StickyHeader } from '@components/style/filterBars.style';
import {
  ProductsKPIsContainer,
  ProductsKPIGrid,
} from '@components/style/products.style';
import {
  ListHeaderContainer,
  ListItemContainer,
} from '@components/style/tables.style';
import { useBrandContext } from '@contexts/BrandContext';
import { useOperationsContext } from '@contexts/OperationsContext';
import { getAnalyticsProductsData } from '@services/analytics.service';

export const ProductsScreen: FC = () => {
  const params = useParams<{ uniqueUrl: string }>();
  const { currentOperation, noOperations } = useOperationsContext();
  const { brandNotFound, brand } = useBrandContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [imageToShowSrc, setImageToShowSrc] = useState('');
  const [activeFilter, setActiveFilter] = useState<ProductsFilter>(
    ProductsFilter.BEST_SELLERS,
  );
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const navigate = useNavigate();

  useEffect(() => {
    forceCheck();
  }, [searchQuery, activeFilter, selectedCategory]);

  useEffect(() => {
    if (brandNotFound) {
      navigate('/404');
    } else if (noOperations) {
      navigate(`/${brand?.uniqueUrl}/operations`);
    }
  }, [brandNotFound, brand?.uniqueUrl, noOperations, navigate]);

  const { data, isLoading } = useQuery<GetAnalyticsProductsDataResponse>({
    queryKey: [
      `analytics-products-${params?.uniqueUrl}-${brand?.id}-${currentOperation?.id}`,
    ],
    queryFn: () => {
      return getAnalyticsProductsData(
        params?.uniqueUrl,
        brand?.id,
        currentOperation?.id,
      );
    },
  });

  const sortedProductCategories = data?.productTypes.sort((a, b) =>
    a.localeCompare(b),
  );

  const filterProducts = () => {
    return Object.values(data?.products ?? []).filter(
      (product: ProductSellThrough) => {
        return (
          // Filter by category
          (selectedCategory === 'all' ||
            product.product_type === selectedCategory) &&
          // Filter by search query
          product.product_title
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      },
    );
  };

  const filteredProducts = filterProducts();
  const productsToDisplay = sortProducts(filteredProducts, activeFilter);

  return (
    <HeaderSidebarContainer pageTitle={`Products ${brand?.name} | The Bradery`}>
      {imageToShowSrc !== '' && (
        <ProductImageViewer
          imgSrc={imageToShowSrc}
          setImageToShowSrc={setImageToShowSrc}
        />
      )}
      <ProductsKPIsContainer>
        <ProductsKPIGrid id="productKPIGrid">
          <KPI
            label="products sold"
            value={data?.totalProductsSold}
            isLoading={isLoading}
          />
          <KPI
            label="sellthrough rate"
            value={data?.globalSellThrough}
            style="percent"
            maximumFractionDigits={2}
            isLoading={isLoading}
          />
        </ProductsKPIGrid>
      </ProductsKPIsContainer>

      <ScrollSync>
        <>
          <StickyHeader>
            <ProductsFilterBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              categories={sortedProductCategories ?? []}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
            />

            <ScrollSyncPane>
              <ListHeaderContainer>
                <ProductListHeader
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                />
              </ListHeaderContainer>
            </ScrollSyncPane>
          </StickyHeader>

          <ScrollSyncPane>
            <ListItemContainer>
              {productsToDisplay.map((product) => (
                <ProductListItem
                  product={product}
                  key={product.product_id}
                  setImageToShowSrc={setImageToShowSrc}
                />
              ))}
            </ListItemContainer>
          </ScrollSyncPane>
        </>
      </ScrollSync>
    </HeaderSidebarContainer>
  );
};
