import { ProductVariantSellThrough } from '@tb-brand-dashboard/types';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ProductVariantInfo } from './ProductListVariants.types';

import { colors } from '@theme/colors.theme';

const Container = styled('div')<{
  productTitle?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.productTitle ? 'flex-end' : 'flex-start')};

  ${(props) => {
    if (props.productTitle) return `margin-right: 50px;`;
  }}
  width: 100%;
`;

const VariantText = styled.div`
  font-family: Helvetica, sans-serif;
  color: ${colors.primary};
  font-size: 14px;

  margin-bottom: 4px;
`;

const VariantTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

type ProductListVariantsProps = {
  variants: ProductVariantSellThrough[];
  variantInfo: ProductVariantInfo;
  productTitle?: boolean;
};

export const ProductListVariants: FC<ProductListVariantsProps> = ({
  variants,
  variantInfo,
  productTitle,
}) => {
  const intl = useIntl();
  const sortedVariants = [...variants].sort((a, b) => a.position - b.position);

  const variantTexts = (variant: ProductVariantSellThrough) => {
    let valueToDisplay;

    if (variantInfo === ProductVariantInfo.SELL_THROUGH) {
      valueToDisplay = intl.formatNumber(variant.sell_through, {
        style: 'percent',
        maximumFractionDigits: 2,
      });
    } else if (variantInfo === ProductVariantInfo.TITLE) {
      valueToDisplay = variant.variant_title;
    } else if (variantInfo === ProductVariantInfo.AVAILABLE) {
      valueToDisplay = intl.formatNumber(variant.available_inventory);
    } else {
      valueToDisplay = intl.formatNumber(variant.quantity_sold);
    }

    return <VariantText key={variant.variant_id}>{valueToDisplay}</VariantText>;
  };

  return (
    <Container productTitle={productTitle}>
      {!productTitle ? (
        <VariantTextContainer>
          {sortedVariants.map((variantText) => variantTexts(variantText))}
        </VariantTextContainer>
      ) : (
        sortedVariants.map((variantText) => variantTexts(variantText))
      )}
    </Container>
  );
};
