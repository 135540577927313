import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

export const ListItemContainer = styled.div`
  overflow-x: auto;
`;

export const ListHeaderContainer = styled.div`
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ListColumnHeaderContainer = styled('div')<{
  width?: string;
  backgroundColor?: string;
  first?: boolean;
}>`
  display: flex;
  width: ${(props) => props.width};
  ${(props) =>
    props.backgroundColor
      ? `background-color: ${props.backgroundColor}`
      : null};

  padding-right: 24px;

  ${(props) => (props.first ? 'padding-left: 8px;' : '')}
`;

export const ListItemSubContainer = styled('div')<{
  width?: string;
  backgroundColor?: string;
  first?: boolean;
}>`
  display: flex;
  height: 100%;

  ${(props) =>
    props.backgroundColor
      ? `background-color: ${props.backgroundColor}`
      : null};

  width: ${(props) => props.width};
  padding-right: 24px;
  ${(props) => (props.first ? 'padding-left: 8px;' : '')}
  align-items: center;
`;

export const ListItemLineRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ListText = styled.span`
  font-family: Helvetica, sans-serif;
  color: ${colors.primary};
  font-size: 16px;
`;
