import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

export const TitleBullet = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${colors.primary};
  align-self: center;
  margin-right: 14px;
`;

export const Title = styled('span')<{
  customFontSize?: number;
}>`
  font-family: Helvetica;
  font-weight: bold;
  font-size: ${(props) => props.customFontSize || 16}px;
  letter-spacing: 0.9px;
  color: ${colors.primary};
`;
