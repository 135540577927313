import { VisitorsPerDay, ProductsSoldPerDay } from '@tb-brand-dashboard/types';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Oval } from 'react-loader-spinner';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { prepareChartData } from './VisitsProductsSoldChart.utils';

import {
  ChartLoadingContainer,
  StyledResponsiveContainer,
} from '@style-components/charts.style';
import { colors } from '@theme/colors.theme';

type VisitsProductsSoldChartProps = {
  visitorsPerDay?: VisitorsPerDay;
  productsSoldPerDay?: ProductsSoldPerDay;
  isLoading: boolean;
};

export const VisitsProductsSoldChart: FC<VisitsProductsSoldChartProps> = ({
  productsSoldPerDay,
  visitorsPerDay,
  isLoading,
}) => {
  const intl = useIntl();

  const data = prepareChartData({ visitorsPerDay, productsSoldPerDay });

  return (
    <StyledResponsiveContainer>
      <ResponsiveContainer width="100%" height={300}>
        {isLoading ? (
          <ChartLoadingContainer>
            <Oval
              color={colors.paleBlue}
              secondaryColor={colors.paleBlueSecondary}
              height={50}
              width={50}
            />
          </ChartLoadingContainer>
        ) : (
          <BarChart width={730} height={250} data={data}>
            <CartesianGrid vertical={false} strokeWidth={0.5} />
            <YAxis
              yAxisId="left"
              hide={true}
              fontFamily={'Helvetica, sans-serif'}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              hide={true}
              fontFamily={'Helvetica, sans-serif'}
            />
            <Tooltip formatter={(label) => intl.formatNumber(+label)} />
            <Legend
              align="right"
              verticalAlign="top"
              wrapperStyle={{
                paddingTop: '16px',
                paddingBottom: '16px',
              }}
              iconType="square"
              iconSize={16}
            />
            <Bar
              yAxisId="left"
              dataKey="Visitors"
              fill={colors.paleBlue}
              radius={[5, 5, 0, 0] as number & [number, number, number, number]}
            />
            <Bar
              yAxisId="right"
              dataKey="Products sold"
              fill={colors.accent}
              radius={[5, 5, 0, 0] as number & [number, number, number, number]}
            />
            <XAxis
              dataKey="name"
              strokeWidth={0}
              fontFamily={'Helvetica, sans-serif'}
              fontSize={10}
              tick={{ fill: colors.primary }}
            />
            <XAxis />
          </BarChart>
        )}
      </ResponsiveContainer>
    </StyledResponsiveContainer>
  );
};
