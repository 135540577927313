import Select, { components } from 'react-select';

import { colors } from '@theme/colors.theme';

interface Props {
  operationValues: { value: string | number; label: string }[];
  value;
  onChangeValue;
}

const operationsSelectStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    margin: 0,
    color: colors.accent,
    letterSpacing: '0.43px',
    fontSize: 15,
    fontWeight: 700,
  }),
  control: (base, state) => ({
    ...base,
    background: colors.transparent,
    // Overwrittes the different states of border
    borderColor: colors.transparent,
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 700,
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
  }),
};

export const OperationsSelect = ({
  operationValues,
  value,
  onChangeValue,
}: Props) => {
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img src="/assets/imgs/chevronDown.svg" alt="" />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <div>
      <Select
        options={operationValues}
        styles={operationsSelectStyles}
        value={value}
        isSearchable={false}
        components={{ DropdownIndicator }}
        onChange={onChangeValue}
        menuPlacement="auto"
        placeholder="Sélectionner..."
      />
    </div>
  );
};
