import { useQuery } from '@tanstack/react-query';
import { Brand } from '@tb-brand-dashboard/types';
import { FC, ReactNode, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { getBrandData } from '@services/brands.service';

type BrandState = {
  brandNotFound?: boolean;
  brand?: Brand;
  isBrandDataLoading: boolean;
};

type BrandProviderProps = {
  children: ReactNode;
};

const BrandContext = createContext<BrandState | undefined>(undefined);

export const BrandProvider: FC<BrandProviderProps> = ({ children }) => {
  const location = useLocation();

  const uniqueUrl = location.pathname
    .split('/')
    .filter((pathnameElement) => pathnameElement !== '')[0];

  const { data, isLoading: isBrandDataLoading } = useQuery({
    queryKey: [`brand-${uniqueUrl}`],
    queryFn: () => {
      return getBrandData(uniqueUrl);
    },
  });

  const brand = data?.brand;
  const brandNotFound = data?.brandNotFound;

  return (
    <BrandContext.Provider
      value={{
        isBrandDataLoading,
        brand,
        brandNotFound,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};

export const useBrandContext = () => {
  const context = useContext(BrandContext);

  if (!context) {
    throw new Error('Component must be used within a BrandProvider');
  }

  return context;
};
