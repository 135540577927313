import { json, scaleQuantile } from 'd3';
import { FC, memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';

import { MapContainer } from '@style-components/demography.style';
import { colors } from '@theme/colors.theme';

interface Props {
  setMapTooltipConcent;
  data;
}

const FranceMap: FC<Props> = memo(({ setMapTooltipConcent, data }) => {
  const intl = useIntl();

  const [franceGeoJson, setFranceGeoJson] = useState([]);

  if (franceGeoJson.length === 0) {
    json('/france.json').then(function (data) {
      setFranceGeoJson(data.features);
    });
  }

  const colorScale = scaleQuantile<string>()
    .domain(Object.values(data))
    .range([
      colors.background,
      '#fce5db',
      '#fdd6c8',
      '#fdc6b5',
      '#fea78f',
      '#fe987c',
      '#fe8869',
      colors.accent,
    ]);

  return (
    <MapContainer>
      <ComposableMap
        projectionConfig={{
          scale: 2400,
          center: [2.454071, 46.279229],
        }}
        projection="geoMercator"
        data-tip=""
        style={{ width: '100%', height: 'auto' }}
      >
        <Geographies geography={franceGeoJson}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const currRegionOrders = data[+geo.properties.code];
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setMapTooltipConcent(
                      `${geo.properties.nom} - ${
                        intl.formatNumber(currRegionOrders) || 0
                      } order${currRegionOrders > 1 ? 's' : ''}`,
                    );
                  }}
                  onMouseLeave={() => {
                    setMapTooltipConcent('');
                  }}
                  style={{
                    default: {
                      fill: currRegionOrders
                        ? colorScale(currRegionOrders)
                        : colors.background,
                      outline: 'none',
                      stroke: colors.border,
                    },
                    hover: {
                      fill: currRegionOrders
                        ? colorScale(currRegionOrders)
                        : colors.background,
                      outline: 'none',
                    },
                    pressed: {
                      fill: currRegionOrders
                        ? colorScale(currRegionOrders)
                        : colors.background,
                      outline: 'none',
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </MapContainer>
  );
});

export { FranceMap };
