import { FC } from 'react';
import styled from 'styled-components';

import { ProductsFilter } from './products/ProductsFilterBar/ProductsFilterBar.types';

import { Arrow } from '@style-components/products.style';
import { colors } from '@theme/colors.theme';

const Container = styled.div<{
  isClickable?: boolean;
}>`
  display: flex;
  font-family: Helvetica, sans-serif;
  color: ${colors.primary};
  font-size: 14px;
  padding: 10px 0;

  align-items: center;

  &:hover {
    ${(props) => (props.isClickable ? 'cursor: pointer;' : '')}
  }
`;

type ListColumnHeaderProps = {
  title: string;
  upFilter?: ProductsFilter;
  downFilter?: ProductsFilter;
  activeFilter?: ProductsFilter;
  setActiveFilter?: (string) => void;
};

export const ListColumnHeader: FC<ListColumnHeaderProps> = ({
  downFilter,
  upFilter,
  activeFilter,
  setActiveFilter,
  title,
}) => {
  const hasFilters = !!(activeFilter && downFilter && upFilter);

  const onColumnHeaderClickedHandler = () => {
    if (hasFilters) {
      if (activeFilter === upFilter) {
        setActiveFilter(downFilter);
      } else if (activeFilter === downFilter) {
        setActiveFilter(upFilter);
      } else {
        setActiveFilter(downFilter);
      }
    }
  };

  return (
    <Container onClick={onColumnHeaderClickedHandler} isClickable={hasFilters}>
      {title}

      {hasFilters &&
        (activeFilter === upFilter || activeFilter === downFilter) && (
          <Arrow
            src="/assets/imgs/orangeArrow.svg"
            up={activeFilter === upFilter}
          />
        )}
    </Container>
  );
};
