import { MdSearch } from 'react-icons/md';

import { ProductCategorySelect } from '../ProductCategory.select';

import { ProductsFilter } from './ProductsFilterBar.types';

import { Button } from '@components/forms/button.component';
import { IconTextInput } from '@components/forms/iconText.input';
import {
  ProductsFilterBarContainer,
  ProductsFilterSubContainer,
} from '@style-components/products.style';
import { Breakpoint } from '@utils/general.utils';

const searchInputBreakPoints: Breakpoint[] = [
  { breakpoint: 'normal', properties: { width: '400px' } },
  { breakpoint: '1320px', properties: { width: '350px' } },
  { breakpoint: '1250px', properties: { width: '300px' } },
  { breakpoint: '1190px', properties: { width: '250px' } },
  { breakpoint: '1024px', properties: { width: '300px' } },
  { breakpoint: '879px', properties: { width: '250px' } },
  { breakpoint: '830px', properties: { width: '50%' } },
  { breakpoint: '650px', properties: { width: '100%' } },
  { breakpoint: '375px', properties: { height: '35px' } },
];

const filterButtonMarginBreakPoints: Breakpoint[] = [
  { breakpoint: 'normal', properties: { 'margin-left': '16px' } },
  { breakpoint: '430px', properties: { 'margin-left': '8px' } },
  { breakpoint: '350px', properties: { 'margin-left': '4px' } },
];

const generateCategoryOptions = (categories: string[]) => {
  const generatedCategoryOptions = categories.map((x) => ({
    value: x,
    label: x,
  }));

  return [
    { value: 'all', label: 'All categories' },
    ...generatedCategoryOptions,
  ];
};

type ProductsFilterBarProps = {
  searchQuery?: string;
  setSearchQuery?: (text: string) => void;
  categories: string[];
  activeFilter?: ProductsFilter;
  setActiveFilter: (filter: ProductsFilter) => void;
  selectedCategory?: string;
  setSelectedCategory: (category: string) => void;
};

export const ProductsFilterBar = ({
  searchQuery,
  setSearchQuery,
  categories,
  activeFilter,
  setActiveFilter,
  setSelectedCategory,
  selectedCategory,
}: ProductsFilterBarProps) => {
  const onActiveFilterChangeHandler = (activeButton: ProductsFilter) => {
    let filterToApply = activeButton;
    if (activeFilter === filterToApply) {
      filterToApply = ProductsFilter.DOWN_PRODUCT_NAMES;
    }
    setActiveFilter(filterToApply);
  };

  const onCategorySelectionChangeHandler = (selection: {
    value: string;
    label: string;
  }) => {
    setSelectedCategory(selection.value);
  };

  const categoryOptions = generateCategoryOptions(categories);

  const currSelectedCategory = categoryOptions.find(
    (category) => category.value === selectedCategory,
  );

  return (
    <ProductsFilterBarContainer>
      <ProductsFilterSubContainer left>
        <IconTextInput
          icon={(props) => <MdSearch size={20} {...props} />}
          placeholder="Search a product"
          breakpoints={searchInputBreakPoints}
          onChange={setSearchQuery}
          value={searchQuery}
        />
        <ProductCategorySelect
          options={categoryOptions}
          value={currSelectedCategory}
          onChangeValue={onCategorySelectionChangeHandler}
        />
      </ProductsFilterSubContainer>
      <ProductsFilterSubContainer right>
        <Button
          text="Best Sellers"
          active={activeFilter === ProductsFilter.BEST_SELLERS}
          onClick={() =>
            onActiveFilterChangeHandler(ProductsFilter.BEST_SELLERS)
          }
          isToggle
        />
        <Button
          text="Worst Sellers"
          active={activeFilter === ProductsFilter.WORST_SELLERS}
          onClick={() =>
            onActiveFilterChangeHandler(ProductsFilter.WORST_SELLERS)
          }
          breakpoints={filterButtonMarginBreakPoints}
          isToggle
        />
        <Button
          text="Out of Stock"
          active={activeFilter === ProductsFilter.OUT_OF_STOCK}
          onClick={() =>
            onActiveFilterChangeHandler(ProductsFilter.OUT_OF_STOCK)
          }
          breakpoints={filterButtonMarginBreakPoints}
          isToggle
        />
      </ProductsFilterSubContainer>
    </ProductsFilterBarContainer>
  );
};
