import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { MenuIcon } from '@style-components/MenuIcon.button';
import { colors } from '@theme/colors.theme';
import { extractBrandUUIDFromPath } from '@utils/general.utils';

const NavBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: ${colors.background};
  border-bottom: 1px solid ${colors.border};
  overflow: hidden;
`;

const TheBraderyLogo = styled.img`
  height: auto;
  width: 140px;
`;

const TheBraderyLogoContainer = styled.div``;

interface Props {
  toggleSidebar;
}

export const Navbar = ({ toggleSidebar }: Props) => {
  const location = useLocation();
  const brandUUID = extractBrandUUIDFromPath(location.pathname);
  return (
    <NavBar>
      <MenuIcon onClick={toggleSidebar} />
      <TheBraderyLogoContainer>
        <Link to={`/${brandUUID}`}>
          <TheBraderyLogo
            src="/assets/imgs/theBraderyLogo.svg"
            alt="The Bradery's logo"
          />
        </Link>
      </TheBraderyLogoContainer>
    </NavBar>
  );
};
