import { FC } from 'react';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

import { colors } from '@theme/colors.theme';

const Container = styled.div`
  background-color: ${colors.background};
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TheBraderyLogo = styled.img`
  position: fixed;
  top: 16px;
  height: 75px;
  width: auto;
`;

export const LoadingScreen: FC = () => {
  return (
    <Container>
      <TheBraderyLogo
        src="/assets/imgs/theBraderyLogo.svg"
        alt="The Bradery's logo"
      />

      <Oval
        color={colors.paleBlue}
        secondaryColor={colors.paleBlueSecondary}
        height={50}
        width={50}
      />
    </Container>
  );
};
