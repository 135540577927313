import { UserAgeBrackets } from '@tb-brand-dashboard/types';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import { CustomDemographyPieChartTooltip } from '@style-components/demography.style';
import { colors } from '@theme/colors.theme';
import '@style-components/charts.css';

const pieColors = {
  '18-24 y.o.': colors.pieChart18_24,
  '25-34 y.o.': colors.pieChart24_34,
  '35-44 y.o.': colors.pieChart35_44,
  '45-54 y.o.': colors.pieChart45_54,
  '55+ y.o.': colors.primary,
};

interface Props {
  ageBrackets: UserAgeBrackets;
}

export const DemographyPieChart: FC<Props> = ({ ageBrackets }: Props) => {
  const intl = useIntl();

  const createPieData = () => {
    const ageBracketsEntries = Object.entries(ageBrackets);
    const totalNumber = ageBracketsEntries.reduce(
      (acc, curr) => acc + +curr[1],
      0,
    );

    return ageBracketsEntries.map(([key, value]) => {
      return {
        name: `${key} y.o.`,
        value: value / totalNumber,
      };
    });
  };

  const renderCustomTooltip = (data) => {
    return (
      <CustomDemographyPieChartTooltip>
        {data.payload[0] ? (
          <span>
            {data.payload[0].name} :{' '}
            {intl.formatNumber(data.payload[0].value, {
              style: 'percent',
              maximumFractionDigits: 2,
            })}
          </span>
        ) : null}
      </CustomDemographyPieChartTooltip>
    );
  };

  const data = createPieData();

  return (
    <ResponsiveContainer minHeight={450} id={'demographyPieChart'}>
      <PieChart>
        <Tooltip content={renderCustomTooltip} />
        <Pie data={data} dataKey="value" nameKey="name">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={pieColors[entry.name]} />
          ))}
        </Pie>
        <Legend iconType="square" iconSize={16} />
      </PieChart>
    </ResponsiveContainer>
  );
};
