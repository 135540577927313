import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { NavigationElement, ActiveItemMarker } from './sidebar.style';

import { extractBrandUUIDFromPath } from '@utils/general.utils';

interface Props {
  label: string;
  itemPathName: string;
}

const StyledLink = styled(Link)`
  display: flex;
`;

const navigationItemIsActive = (currPathname: string, itemPathName: string) => {
  const paths = currPathname.split('/').splice(1);

  if (paths.length === 1 && itemPathName === '') return true;

  return paths[1] === itemPathName;
};

export const SidebarNavigationItem: FC<Props> = ({ label, itemPathName }) => {
  const location = useLocation();

  const currentItemIsActive = navigationItemIsActive(
    location.pathname,
    itemPathName,
  );

  const brandUUID = extractBrandUUIDFromPath(location.pathname);

  return (
    <StyledLink to={`/${brandUUID}/${itemPathName}`}>
      {currentItemIsActive ? <ActiveItemMarker /> : null}

      <NavigationElement active={currentItemIsActive}>
        {label}
      </NavigationElement>
    </StyledLink>
  );
};
