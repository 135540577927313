import { useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';

import { HeaderSidebarContainer } from '@components/containers/HeaderSidebarContainer.component';
import { DemographyPieChart } from '@components/dashboards/demography/DemographyPieChart/DemographyPieChart';
import { FranceMap } from '@components/dashboards/demography/FranceMap/FranceMap';
import { KPI } from '@components/dashboards/KPI.component';
import {
  DemographyKPIContainer,
  DemographyMainContainer,
  DemographySubContainer,
  LoaderContainer,
  StyledTooltip,
} from '@components/style/demography.style';
import { useBrandContext } from '@contexts/BrandContext';
import { useOperationsContext } from '@contexts/OperationsContext';
import { getAnalyticsDemographyData } from '@services/analytics.service';
import { colors } from '@theme/colors.theme';
import { calculateProportion18_34 } from '@utils/kpi.utils';

export const DemographyScreen: FC = () => {
  const params = useParams<{ uniqueUrl: string }>();
  const { currentOperation, noOperations } = useOperationsContext();
  const { brandNotFound, brand } = useBrandContext();
  const navigate = useNavigate();

  const [mapTooltipContent, setMapTooltipConcent] = useState('');

  useEffect(() => {
    if (brandNotFound) {
      navigate('/404');
    } else if (noOperations) {
      navigate(`/${brand?.uniqueUrl}/operations`);
    }
  }, [brandNotFound, brand?.uniqueUrl, navigate, noOperations]);

  const { data, isLoading } = useQuery({
    queryKey: [
      `analytics-demography-${params?.uniqueUrl}-${brand?.id}-${currentOperation?.id}`,
    ],
    queryFn: () => {
      return getAnalyticsDemographyData(
        params?.uniqueUrl,
        brand?.id,
        currentOperation?.id,
      );
    },
  });

  const proportion18_34 = calculateProportion18_34(data?.customersAgeBrackets);
  const parisianBuyers = data?.parisianBuyersShare;

  return (
    <HeaderSidebarContainer
      pageTitle={`Demographics ${brand?.name} | The Bradery`}
    >
      <DemographyMainContainer>
        <DemographySubContainer>
          <DemographyKPIContainer>
            <KPI
              label="buyers"
              subLabel="18/34 y.o."
              value={proportion18_34}
              style="percent"
              maximumFractionDigits={2}
              isLoading={isLoading}
            />
          </DemographyKPIContainer>
          {!isLoading ? (
            <DemographyPieChart ageBrackets={data?.customersAgeBrackets} />
          ) : (
            <LoaderContainer>
              <Oval
                color={colors.paleBlue}
                secondaryColor={colors.paleBlueSecondary}
                height={50}
                width={50}
              />
            </LoaderContainer>
          )}
        </DemographySubContainer>
        <DemographySubContainer isNotFirst>
          <DemographyKPIContainer>
            <KPI
              label="Parisian buyers"
              value={parisianBuyers}
              style="percent"
              maximumFractionDigits={2}
              isLoading={isLoading}
            />
          </DemographyKPIContainer>
          {!isLoading ? (
            <>
              <FranceMap
                setMapTooltipConcent={setMapTooltipConcent}
                data={data?.customersByRegion}
              />
              <StyledTooltip>{mapTooltipContent}</StyledTooltip>
            </>
          ) : (
            <LoaderContainer>
              <Oval
                color={colors.paleBlue}
                secondaryColor={colors.paleBlueSecondary}
                height={50}
                width={50}
              />
            </LoaderContainer>
          )}
        </DemographySubContainer>
      </DemographyMainContainer>
    </HeaderSidebarContainer>
  );
};
