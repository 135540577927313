import { GetBrandsOperation } from '@tb-brand-dashboard/types';
import axios from 'axios';

const ROUTE_BASE = 'operations';

// ✅️
export const getBrandOperations = async (uniqueUrl: string) => {
  const res = await axios.get<GetBrandsOperation>(`${ROUTE_BASE}`, {
    params: { uniqueUrl },
  });

  return res.data;
};
