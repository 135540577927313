import { FC } from 'react';
import styled from 'styled-components';

import { ListColumnHeaderContainer } from '../../style/tables.style';
import { ListColumnHeader } from '../ListColumnHeader.component';

import { ProductsFilter } from './ProductsFilterBar/ProductsFilterBar.types';

import { colors } from '@theme/colors.theme';

const ProductListHeaderContainer = styled.div`
  display: flex;
  margin-top: 20px;
  border-bottom: solid 1px ${colors.border};
  min-width: 900px;

  @media only screen and (max-width: 830px) {
    margin-top: 8px;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 4px;
  }
`;

type ProductListHeaderProps = {
  activeFilter: ProductsFilter;
  setActiveFilter: (filter: ProductsFilter) => void;
};

export const ProductListHeader: FC<ProductListHeaderProps> = ({
  activeFilter,
  setActiveFilter,
}) => {
  const setFilter = (filter: ProductsFilter) => {
    setActiveFilter(filter);
  };

  return (
    <ProductListHeaderContainer>
      <ListColumnHeaderContainer width="52%">
        <ListColumnHeader
          title={'Products'}
          upFilter={ProductsFilter.UP_PRODUCT_NAMES}
          downFilter={ProductsFilter.DOWN_PRODUCT_NAMES}
          activeFilter={activeFilter}
          setActiveFilter={setFilter}
        />
      </ListColumnHeaderContainer>
      <ListColumnHeaderContainer width="16%">
        <ListColumnHeader
          title="Quantity sold"
          upFilter={ProductsFilter.WORST_SELLERS}
          downFilter={ProductsFilter.BEST_SELLERS}
          activeFilter={activeFilter}
          setActiveFilter={setFilter}
        />
      </ListColumnHeaderContainer>
      <ListColumnHeaderContainer width="16%">
        <ListColumnHeader
          title="Remaining quantity"
          upFilter={ProductsFilter.UP_AVAILABLE}
          downFilter={ProductsFilter.DOWN_AVAILABLE}
          activeFilter={activeFilter}
          setActiveFilter={setFilter}
        />
      </ListColumnHeaderContainer>
      <ListColumnHeaderContainer width="16%">
        <ListColumnHeader
          title="Sellthrough rate"
          upFilter={ProductsFilter.UP_SELL_THROUGH}
          downFilter={ProductsFilter.DOWN_SELL_THROUGH}
          activeFilter={activeFilter}
          setActiveFilter={setFilter}
        />
      </ListColumnHeaderContainer>
    </ProductListHeaderContainer>
  );
};
