import { ProductSellThrough } from '@tb-brand-dashboard/types';

import { ProductsFilter } from '@components/dashboards/products/ProductsFilterBar/ProductsFilterBar.types';

export const sortProducts = (
  products: ProductSellThrough[],
  activeFilter: ProductsFilter,
) => {
  const sortingFunctions = {
    [ProductsFilter.BEST_SELLERS]: (
      a: ProductSellThrough,
      b: ProductSellThrough,
    ) => {
      if (b.quantity_sold !== a.quantity_sold)
        return b.quantity_sold - a.quantity_sold;
      if (b.available_inventory !== a.available_inventory)
        return a.available_inventory - b.available_inventory;
      return a.sell_through - b.sell_through;
    },
    [ProductsFilter.WORST_SELLERS]: (
      a: ProductSellThrough,
      b: ProductSellThrough,
    ) => {
      if (b.quantity_sold !== a.quantity_sold)
        return a.quantity_sold - b.quantity_sold;
      if (b.available_inventory !== a.available_inventory)
        return b.available_inventory - a.available_inventory;
      return b.sell_through - a.sell_through;
    },
    [ProductsFilter.UP_PRODUCT_NAMES]: (
      a: ProductSellThrough,
      b: ProductSellThrough,
    ) => {
      return a.product_title
        .toLowerCase()
        .localeCompare(b.product_title.toLowerCase());
    },
    [ProductsFilter.DOWN_PRODUCT_NAMES]: (
      a: ProductSellThrough,
      b: ProductSellThrough,
    ) => {
      return b.product_title
        .toLowerCase()
        .localeCompare(a.product_title.toLowerCase());
    },
    [ProductsFilter.UP_AVAILABLE]: (
      a: ProductSellThrough,
      b: ProductSellThrough,
    ) => {
      return a.available_inventory - b.available_inventory;
    },
    [ProductsFilter.DOWN_AVAILABLE]: (
      a: ProductSellThrough,
      b: ProductSellThrough,
    ) => {
      return b.available_inventory - a.available_inventory;
    },
    [ProductsFilter.UP_SELL_THROUGH]: (
      a: ProductSellThrough,
      b: ProductSellThrough,
    ) => {
      return a.sell_through - b.sell_through;
    },
    [ProductsFilter.DOWN_SELL_THROUGH]: (
      a: ProductSellThrough,
      b: ProductSellThrough,
    ) => {
      return b.sell_through - a.sell_through;
    },
    [ProductsFilter.OUT_OF_STOCK]: (
      a: ProductSellThrough,
      b: ProductSellThrough,
    ) => {
      if (a.available_inventory !== b.available_inventory)
        return a.available_inventory - b.available_inventory;
      if (a.quantity_sold !== b.quantity_sold)
        return b.quantity_sold - a.quantity_sold;
      return b.sell_through - a.sell_through;
    },
  };

  return products.sort(sortingFunctions[activeFilter] || (() => 0));
};
