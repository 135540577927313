import {
  GetAnalyticsDemographyDataResponse,
  GetAnalyticsProductsDataResponse,
  GetAnalyticsOverviewDataResponse,
} from '@tb-brand-dashboard/types';
import axios from 'axios';

const ROUTE_BASE = 'analytics';

export const getAnalyticsOverviewData = async (
  uniqueUrl: string,
  brandId: number,
  operationId: number,
) => {
  const res = await axios.get<GetAnalyticsOverviewDataResponse>(
    `${ROUTE_BASE}/overview`,
    {
      params: {
        uniqueUrl,
        brandId,
        operationUUID: operationId,
      },
    },
  );

  return res.data;
};

export const getAnalyticsProductsData = async (
  uniqueUrl: string,
  brandId: number,
  operationId: number,
): Promise<GetAnalyticsProductsDataResponse> => {
  const res = await axios.get<GetAnalyticsProductsDataResponse>(
    `${ROUTE_BASE}/products`,
    {
      params: {
        uniqueUrl,
        brandId,
        operationUUID: operationId,
      },
    },
  );
  return res.data;
};

export const getAnalyticsDemographyData = async (
  uniqueUrl: string,
  brandId: number,
  operationId: number,
) => {
  const res = await axios.get<GetAnalyticsDemographyDataResponse>(
    `${ROUTE_BASE}/demography`,
    {
      params: {
        uniqueUrl,
        brandId,
        operationUUID: operationId,
      },
    },
  );

  return res.data;
};
