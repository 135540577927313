import { UserAgeBrackets } from '@tb-brand-dashboard/types';

export const calculateProportion18_34 = (
  ageBrackets?: UserAgeBrackets,
): number => {
  if (!ageBrackets) {
    return 0;
  }

  const reducedTotalUsers = +Object.values(ageBrackets).reduce(
    (acc, curr) => +acc + +curr,
    0,
  );

  return reducedTotalUsers !== 0
    ? (ageBrackets['18-24'] + ageBrackets['25-34']) / reducedTotalUsers
    : 0;
};

export const calculateConversionRate = (
  nbVisitors: number,
  nbOrder: number,
) => {
  return nbVisitors === 0 ? 0 : nbOrder / nbVisitors;
};
